export const GET_MULTIJOBS = 'GET_MULTIJOBS';
export const MULTIJOBS_LOADING = 'MULTIJOBS_LOADING';

export const GET_ONE_MULTIJOBS = 'GET_ONE_MULTIJOBS';
export const GET_ONE_MULTIJOBS_FAIL = 'GET_ONE_MULTIJOBS_FAIL';

export const CREATE_MULTIJOBS = 'CREATE_MULTIJOBS';
export const CREATE_MULTIJOBS_SUCCESS = 'CREATE_MULTIJOBS_SUCCESS';
export const CREATE_MULTIJOBS_FAIL = 'CREATE_MULTIJOBS_FAIL';

export const UPDATE_MULTIJOBS = 'UPDATE_MULTIJOBS';
export const UPDATE_MULTIJOBS_FAIL = 'UPDATE_MULTIJOBS_FAIL';

export const DELETE_MULTIJOBS = 'DELETE_MULTIJOBS';
export const DELETE_MULTIJOBS_FAIL = 'DELETE_MULTIJOBS_FAIL';
