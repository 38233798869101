export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_CATEGORIES_FAIL = 'GET_CATEGORIES_FAIL';

export const CATEGORIES_LOADING = 'CATEGORIES_LOADING';

export const CREATE_CATEGORY = 'CREATE_CATEGORY';
export const CREATE_CATEGORY_FAIL = 'CREATE_CATEGORY_FAIL';
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';

export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const DELETE_CATEGORY_FAIL = 'DELETE_CATEGORY_FAIL';

export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const UPDATE_CATEGORY_FAIL = 'UPDATE_CATEGORY_FAIL';

