export const GET_ITEMS = 'GET_ITEMS';
export const ITEMS_LOADING = 'ITEMS_LOADING';

export const GET_ONE_ITEM = 'GET_ONE_ITEM';
export const GET_ONE_ITEM_FAIL = 'GET_ONE_ITEM_FAIL';

export const GET_PAGINATION_ITEMS = 'GET_PAGINATION_ITEMS';
export const PAGINATION_ITEMS_LOADING = 'PAGINATION_ITEMS_LOADING';

export const GET_CATEGORY_ITEMS = 'GET_CATEGORY_ITEMS';
export const GET_CATEGORY_ITEMS_FAIL = 'GET_CATEGORY_ITEMS_FAIL';

export const GET_SUB_CATEGORY_ITEMS = 'GET_SUB_CATEGORY_ITEMS';
export const GET_SUB_CATEGORY_ITEMS_FAIL = 'GET_SUB_CATEGORY_ITEMS_FAIL';

export const CREATE_ITEM = 'CREATE_ITEM';
export const CREATE_ITEM_SUCCESS = 'CREATE_ITEM_SUCCESS';
export const CREATE_ITEM_FAIL = 'CREATE_ITEM_FAIL';

export const UPDATE_ITEM = 'UPDATE_ITEM';
export const UPDATE_ITEM_FAIL = 'UPDATE_ITEM_FAIL';

export const DELETE_ITEM = 'DELETE_ITEM';
export const DELETE_ITEM_FAIL = 'DELETE_ITEM_FAIL';