export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";

export const AUTH_ERROR = "AUTH_ERROR";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const GET_USERS = "GET_USERS";
export const USERS_LOADING = "USERS_LOADING";

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';

export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const UNEXISTING_EMAIL = 'UNEXISTING_EMAIL';
export const RESET_FAIL = 'RESET_FAIL';