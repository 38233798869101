export const GET_SCHOLARSHIPS = 'GET_SCHOLARSHIPS';
export const SCHOLARSHIPS_LOADING = 'SCHOLARSHIPS_LOADING';

export const GET_CATEGORY_SCHOLARSHIPS = 'GET_CATEGORY_SCHOLARSHIPS';
export const GET_CATEGORY_SCHOLARSHIPS_FAIL = 'GET_CATEGORY_SCHOLARSHIPS_FAIL';

export const GET_ACTIVE_SCHOLARSHIPS = 'GET_ACTIVE_SCHOLARSHIPS';
export const GET_ACTIVE_SCHOLARSHIPS_FAIL = 'GET_ACTIVE_SCHOLARSHIPS_FAIL';

export const GET_ARCHIVED_SCHOLARSHIPS = 'GET_ARCHIVED_SCHOLARSHIPS';
export const GET_ARCHIVED_SCHOLARSHIPS_FAIL = 'GET_ARCHIVED_SCHOLARSHIPS_FAIL';

export const GET_SUB_CATEGORY_SCHOLARSHIPS = 'GET_SUB_CATEGORY_SCHOLARSHIPS';
export const GET_SUB_CATEGORY_SCHOLARSHIPS_FAIL = 'GET_SUB_CATEGORY_SCHOLARSHIPS_FAIL';

export const CREATE_SCHOLARSHIP = 'CREATE_SCHOLARSHIP';
export const CREATE_SCHOLARSHIP_SUCCESS = 'CREATE_SCHOLARSHIP_SUCCESS';
export const CREATE_SCHOLARSHIP_FAIL = 'CREATE_SCHOLARSHIP_FAIL';

export const UPDATE_SCHOLARSHIP = 'UPDATE_SCHOLARSHIP';
export const UPDATE_SCHOLARSHIP_FAIL = 'UPDATE_SCHOLARSHIP_FAIL';

export const DELETE_SCHOLARSHIP = 'DELETE_SCHOLARSHIP';
export const DELETE_SCHOLARSHIP_FAIL = 'DELETE_SCHOLARSHIP_FAIL';
